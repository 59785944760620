<template>
  <section id="products-attributes">
    <b-card>
      <b-row>
        <b-col xl="3" md="3">
          <v-select v-model="selectedAttribute" label="title" :options="allAttributes"
            @input="changeSelectedAttribute" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showAttributes" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Attributes
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-attributes" />
          </h4>
          <b-popover target="popover-attributes" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="attributesTable.currentPage"
          :items="attributesTable.items" :fields="attributesTable.fields" :sort-by.sync="attributesTable.sortBy"
          :sort-desc.sync="attributesTable.sortDesc" :sort-direction="attributesTable.sortDirection"
          :filter="attributesTable.filter" :filter-included-fields="attributesTable.filterOn"
          @sort-changed="sortChangedAttributes" @row-clicked="attributesRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="attributesTable.currentPage" :total-rows="attributesTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleAttributesTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="attribute-details-modal" size="xl" cancel-variant="outline-secondary" ok-title="Update"
      cancel-title="Close" centered title="Attribute details" :no-close-on-backdrop="true"
      @ok="handleProductsAttributesSubmit" @hidden="resetAttributeDetailsModal">
      <b-overlay :show="showAttributeValuesDetails" spinner-variant="primary" variant="transparent" blur="3px"
        rounded="sm">
        <b-tabs>
          <b-tab v-for="(lang, index) in langs" :key="index" :title="lang" @click="changeLang2(lang)">
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  {{ currentAttribute }} Attribute values
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="`popover-attribute-values-${index}`" />
                </h4>
                <b-popover target="`popover-attribute-values-${index}`" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <b-table striped hover responsive class="position-relative"
                :current-page="attributeValuesTable.currentPage" :items="attributeValuesTable.items"
                :fields="attributeValuesTable.fields" :sort-by.sync="attributeValuesTable.sortBy"
                :sort-desc.sync="attributeValuesTable.sortDesc" :sort-direction="attributeValuesTable.sortDirection"
                :filter="attributeValuesTable.filter" :filter-included-fields="attributeValuesTable.filterOn"
                @row-clicked="attributeValuesRowClicked">
              </b-table>
              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- pagination -->
                <div>
                  <b-pagination v-model="attributeValuesTable.currentPage" :total-rows="attributeValuesTable.totalRows"
                    first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                    @change="handleAttributeValuesTablePageChange">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
            </b-card>
            <b-card no-body>
              <b-card-header>
                <h4 class="mb-0">
                  Items with this attribute value {{ selectedAttributeValue }}
                  <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                    id="popover-items-attribute-value" />
                </h4>
                <b-popover target="popover-items-attribute-value" triggers="hover" placement="bottom">
                  <span>No data</span>
                </b-popover>
              </b-card-header>
              <b-table striped hover responsive class="position-relative"
                :current-page="attributeValueTable.currentPage" :items="attributeValueTable.items"
                :fields="attributeValueTable.fields" :sort-by.sync="attributeValueTable.sortBy"
                :sort-desc.sync="attributeValueTable.sortDesc" :sort-direction="attributeValueTable.sortDirection"
                :filter="attributeValueTable.filter" :filter-included-fields="attributeValueTable.filterOn">
                <template #cell(attribute_value)="data">
                  <b-form-input v-model="data.item.attribute_value" size="sm"
                    @input="onInputForm2(data.item.sku, data.item.attribute_value, data.item.lang)" />
                </template>
              </b-table>
              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- pagination -->
                <div>
                  <b-pagination v-model="attributeValueTable.currentPage" :total-rows="attributeValueTable.totalRows"
                    first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                    @change="handleAttributeValueTablePageChange">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </b-modal>
    <b-card>
      <b-row>
        <b-col xl="3" md="3">
          <v-select v-model="selectedCategory" label="category" :options="allCategories"
            @input="changeSelectedCategory" />
        </b-col>
        <b-col xl="4" md="4">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValueCategory" placeholder="Search by SKU or Name"
              @keyup.enter="searchProducts" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showProducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Products
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-products" />
          </h4>
          <b-popover target="popover-products" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
              :disabled="disabledButton" @click="openGoogleSheet">
              Google Sheet
            </b-button>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="productsTable.currentPage"
          :items="productsTable.items" :fields="productsTable.fields" :sort-by.sync="productsTable.sortBy"
          :sort-desc.sync="productsTable.sortDesc" :sort-direction="productsTable.sortDirection"
          :filter="productsTable.filter" :filter-included-fields="productsTable.filterOn"
          @sort-changed="sortChangedProducts" @row-clicked="productsRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="productsTable.currentPage" :total-rows="productsTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="product-attributes-details-modal" size="xl" cancel-variant="outline-secondary" ok-title="Update"
        cancel-title="Close" centered title="Product Attributes" :no-close-on-backdrop="true"
        @ok="handleProductAttributesSubmit" @hidden="resetProductAttributesDetailsModal">
        <b-overlay :show="showProductAttributesDetails" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-tabs>
            <b-tab v-for="(lang, index) in langs" :key="index" :title="lang" @click="changeLang1(lang)">
              <b-card no-body>
                <b-card-header>
                  <h4 class="mb-0">
                    SKU: <b>{{ productSKU }}</b> Name: <b>{{ productName }}</b>
                    <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                      id="`popover-products-attributes-${index}`" />
                  </h4>
                  <b-popover target="`popover-products-attributes-${index}`" triggers="hover" placement="bottom">
                    <span>No data</span>
                  </b-popover>
                </b-card-header>
                <b-table striped hover responsive class="position-relative"
                  :current-page="productAttributesTable.currentPage" :items="productAttributesTable.items"
                  :fields="productAttributesTable.fields" :sort-by.sync="productAttributesTable.sortBy"
                  :sort-desc.sync="productAttributesTable.sortDesc"
                  :sort-direction="productAttributesTable.sortDirection" :filter="productAttributesTable.filter"
                  :filter-included-fields="productAttributesTable.filterOn">
                  <template #cell(attribute_value)="data">
                    <b-form-input v-model="data.item.attribute_value" size="sm"
                      @input="onInputForm1(item2.attribute, item2.attribute_value, item[0].lang)" />
                  </template>
                </b-table>
                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                  <!-- pagination -->
                  <div>
                    <b-pagination v-model="productAttributesTable.currentPage"
                      :total-rows="productAttributesTable.totalRows" first-number last-number prev-class="prev-item"
                      next-class="next-item" class="mb-0" @change="handleProductAttributesTablePageChange">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </b-card-body>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BTab,
  BTabs,
  BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BTab,
    BTabs,
    BButton,
    vSelect,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showProducts: true,
      showAttributes: true,
      showProductAttributesDetails: true,
      showAttributeValuesDetails: true,
      selectedCategory: { category_id: 408, category: 'Verdichter Vollhermetisch/Copeland Verdichter' },
      selectedAttribute: '',
      currentAttribute: '',
      selectedAttributeValueID: '',
      selectedAttributeValue: '',
      allCategories: [],
      allAttributes: [],
      searchValueCategory: '',
      queryParamsProducts: {},
      queryParamsProductAttributes: {},
      queryParamsAttributes: {},
      queryParamsAttributeValues: {},
      queryParamsAttributeValue: {},
      productsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'id', label: 'id', sortable: true },
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'category', label: 'category', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productAttributesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'attribute_id',
        sortDesc: false,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'attribute', label: 'attribute', sortable: true },
          { key: 'attribute_value', label: 'attribute value', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      attributesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: false,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'id', label: 'id', sortable: true },
          { key: 'name', label: 'name', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      attributeValuesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'attribute_value',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'attribute_value_id', label: 'attribute value id', sortable: true },
          { key: 'attribute_value', label: 'attribute value', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      attributeValueTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'product_name',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'product_name', label: 'product name', sortable: true },
          { key: 'attribute_value', label: 'attribute value', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productID: '',
      productSKU: '',
      productName: '',
      attributeID: '',
      langID: '',
      langs: ["DE", "EN", "FR", "ES", "IT", "NL", "PL"],
      dataToUpdate1: [],
      dataToUpdate2: [],
      sameValForAll: [],
      disabledButton: false,
    }
  },
  async created() {
    this.queryParamsProducts.category = this.selectedCategory.category_id;

    try {
      await this.getAllCategories();
      await this.getAllAttributes();
      await this.getProducts();
      await this.getAttributes();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    groupBy(arr, prop) {
      const map = new Map(Array.from(arr, obj => [obj[prop], []]))
      arr.forEach(obj => map.get(obj[prop]).push(obj))
      return Array.from(map.values())
    },
    getLangID(lang) {
      if (lang === "DE") {
        return 1
      } else if (lang === "EN") {
        return 2
      } else if (lang === "FR") {
        return 4
      } else if (lang === "ES") {
        return 5
      } else if (lang === "IT") {
        return 6
      } else if (lang === "NL") {
        return 7
      } else if (lang === "PL") {
        return 8
      } else {
        return 0
      }
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllCategories() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-categories/`, {});
        this.allCategories = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getAllAttributes() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-attributes/`, {});
        this.allAttributes = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getProducts() {
      this.showProducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-attributes/`, this.queryParamsProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productsTable.items = results;
          this.productsTable.totalRows = results[0].count * 2;
        }
        else {
          this.productsTable.items = [];
          this.productsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProducts = false;
      }
    },
    async getAttributes() {
      this.showAttributes = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/attributes/`, this.queryParamsAttributes);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.attributesTable.items = results;
          this.attributesTable.totalRows = results[0].count * 2;
        }
        else {
          this.attributesTable.items = [];
          this.attributesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showAttributes = false;
      }
    },
    async getProductAttributes() {
      this.showProductAttributesDetails = true;
      this.queryParamsProductAttributes.id = this.productID;
      this.queryParamsProductAttributes.lang = this.langID;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/product-attributes/`, this.queryParamsProductAttributes);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productAttributesTable.items = results;
          this.productAttributesTable.totalRows = results[0].count * 2;
        }
        else {
          this.productAttributesTable.items = [];
          this.productAttributesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductAttributesDetails = false;
      }
    },
    async getAttributeValues() {
      this.showAttributeValuesDetails = true;
      this.queryParamsAttributeValues.id = this.attributeID;
      this.queryParamsAttributeValues.lang = this.langID;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/attribute-values/`, this.queryParamsAttributeValues);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.attributeValuesTable.items = results;
          this.attributeValuesTable.totalRows = results[0].count * 2;
          this.currentAttribute = results[0].attribute;
        }
        else {
          this.attributeValuesTable.items = [];
          this.attributeValuesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showAttributeValuesDetails = false;
      }
    },
    async getAttributeValue() {
      this.showAttributeValuesDetails = true;
      this.queryParamsAttributeValue.id = this.attributeID;
      this.queryParamsAttributeValue.lang = this.langID;
      this.queryParamsAttributeValue.attribute_value_id = this.selectedAttributeValueID;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/attribute-value/`, this.queryParamsAttributeValue);
        let results = response.data.results;
        if (this.sameValForAll.length > 0) {
          results.forEach((item1, index1) => {
            this.sameValForAll.forEach((item2, index2) => {
              if (item1['sku'] == item2['sku']) {
                results[index1]['attribute_value'] = this.sameValForAll[index2]['attribute_value'];
              }
            })
          })
        }
        if (Array.isArray(results) && results.length > 0) {
          this.attributeValueTable.items = results;
          this.attributeValueTable.totalRows = results[0].count * 2;
        }
        else {
          this.attributeValueTable.items = [];
          this.attributeValueTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showAttributeValuesDetails = false;
      }
    },
    async openGoogleSheet() {
      this.disabledButton = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/run-get-products-attributes/`, { category: this.queryParamsProducts.category });
        setTimeout(() => {
          window.open('https://docs.google.com/spreadsheets/d/1vIHuvomySmmp6rnOo_g8dqTOpXVRqGsmE_GsVh4yMOs/edit#gid=0', '_blank')
        }, 5000);
        this.showToast('success', response.data.message);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
          this.showToast('warning', error.response.data.message);
        }
      } finally {
        this.disabledButton = false;
      }
    },
    async productsRowClicked(row) {
      this.productID = row.id;
      this.productSKU = row.sku;
      this.productName = row.name;
      this.langID = 1;
      await this.getProductAttributes();
      this.$refs['product-attributes-details-modal'].show();
    },
    async attributesRowClicked(row) {
      this.attributeID = row.id;
      this.langID = 1;
      this.attributeValueTable.items = [];
      this.attributeValueTable.totalRows = 0;
      await this.getAttributeValues();
      this.$refs['attribute-details-modal'].show();
    },
    async attributeValuesRowClicked(row) {
      this.selectedAttributeValueID = row.attribute_value_id;
      this.selectedAttributeValue = row.attribute_value;
      await this.getAttributeValue();
    },
    onInputForm1(attribute, attribute_value, lang) {
      const { dataToUpdate1 } = this;
      dataToUpdate1.forEach((item, index) => {
        if (item.attribute === attribute && item.lang === lang) {
          dataToUpdate1.splice(index, 1);
        }
      })
      dataToUpdate1.push({ attribute, attribute_value, lang });
      this.dataToUpdate1 = dataToUpdate1;
    },
    handleProductAttributesSubmit() {
      this.showToast('warning', "Currently, this feature doesn't available");
      // axios
      //   .post(process.env.VUE_APP_ROOT_API + "/product-attributes-update/" + this.productID + "/", {
      //     data: this.dataToUpdate1
      //   }, {
      //     headers: {
      //       "Authorization": `JWT ${this.$store.state.jwt}`,
      //       "Content-Type": "application/json"
      //     },
      //   })
      //   .then(response => {
      //     console.log(response)
      //     this.showToast("success", "Product attributes were updated")
      //   })
      //   .catch(error => {
      //     if (error.response.status === 401) {
      //       this.$router.push({ name: "login" })
      //     }
      //   })
    },
    onInputForm2(sku, attribute_value, lang) {
      const { dataToUpdate2 } = this;
      const attribute_value_id = this.selectedAttributeValueID;
      dataToUpdate2.forEach((item, index) => {
        if (item.sku === sku && item.lang === lang) {
          dataToUpdate2.splice(index, 1);
        }
      })
      dataToUpdate2.push({ sku, attribute_value_id, attribute_value, lang });
      this.dataToUpdate2 = dataToUpdate2;
    },
    makeSameValForAll(sku, attribute_value, lang) {
      const { dataToUpdate2 } = this;
      this.sameValForAll.push({ sku: sku, attribute_value: attribute_value, lang: lang });
      const langsIDs = [1, 2, 4, 5, 6, 7, 8];
      langsIDs.forEach(item => {
        if (item != lang) {
          dataToUpdate2.push({ sku: sku, attribute_value: attribute_value, lang: item });
        }
      })
      this.dataToUpdate2 = dataToUpdate2;
    },
    async handleProductsAttributesSubmit() {
      if (this.dataToUpdate2.length > 0) {
        await axios
          .post(process.env.VUE_APP_ROOT_API + "/products-attributes-update/" + this.attributeID + "/", {
            data: this.dataToUpdate2
          }, {
            headers: {
              "Authorization": `JWT ${this.$store.state.jwt}`,
              "Content-Type": "application/json"
            },
          })
          .then(response => {
            console.log(response);
            this.showToast("success", "Products attributes were updated");
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: "login" });
            }
          })
      }
      else {
        this.showToast('warning', "There is nothing to update");
      }
    },
    resetProductAttributesDetailsModal() {
      this.productID = '';
      this.productSKU = '';
      this.productName = '';
      this.langID = '';
      this.dataToUpdate1 = [];
    },
    resetAttributeDetailsModal() {
      this.attributeID = '';
      this.langID = '';
      this.selectedAttributeValueID = '';
      this.selectedAttributeValue = '';
      this.dataToUpdate2 = [];
    },
    async changeSelectedCategory() {
      this.searchValueCategory = '';
      delete this.queryParamsProducts["category"];
      this.queryParamsProducts.page = 1;
      this.queryParamsProducts.category = this.selectedCategory.category_id;
      this.disabledButton = false;
      await this.getProducts();
    },
    async changeSelectedAttribute() {
      this.searchValueAttribute = '';
      delete this.queryParamsAttributes["attribute"];
      this.queryParamsAttributes.page = 1;
      this.queryParamsAttributes.attribute = this.selectedAttribute;
      await this.getAttributes();
    },
    async changeLang1(lang) {
      this.langID = this.getLangID(lang);
      await this.getProductAttributes();
    },
    async changeLang2(lang) {
      this.langID = this.getLangID(lang);
      await this.getAttributeValues();
      await this.getAttributeValue();
    },
    async searchProducts() {
      this.selectedCategory = null;
      delete this.queryParamsProducts["category"];
      this.queryParamsProducts.page = 1;
      this.queryParamsProducts.search = '%' + this.searchValueCategory.toLowerCase() + '%';
      this.disabledButton = true;
      await this.getProducts();
    },
    async sortChangedProducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsProducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsProducts.ordering = value.sortBy;
      }
      await this.getProducts();
    },
    async sortChangedAttributes(value) {
      if (value.sortDesc === true) {
        this.queryParamsAttributes.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsAttributes.ordering = value.sortBy;
      }
      await this.getAttributes();
    },
    async handleProductsTablePageChange(value) {
      this.queryParamsProducts.page = value;
      await this.getProducts();
    },
    async handleAttributesTablePageChange(value) {
      this.queryParamsAttributes.page = value;
      await this.getAttributes();
    },
    async handleProductAttributesTablePageChange(value) {
      this.queryParamsProductAttributes.page = value;
      await this.getProductAttributes();
    },
    async handleAttributeValuesTablePageChange(value) {
      this.queryParamsAttributeValues.page = value;
      await this.getAttributeValues();
    },
    async handleAttributeValueTablePageChange(value) {
      this.queryParamsAttributeValue.page = value;
      await this.getAttributeValue();
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
